#main-header{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        flex-wrap:wrap;         
        padding-bottom:  get-vw(0px);  
        max-width: 100%;
        text-align: left;
        width: 100%;      
        position: relative;
        margin: 0 auto;
        .content{ 
            display: flex;
            flex-direction: row;
            justify-content:space-between;
            flex-wrap:wrap;
            width: 100%;  
            max-width: 90%;
            padding-top: get-vw(0px);  
            align-items:center;
            position: relative;
            h1{
                width: 100%;
                display: block;
                padding-top: get-vw(10px);  
                padding-bottom:  get-vw(10px);  
                max-width:  get-vw(560px); 
                padding-right: get-vw(40px); 
                font-size: get-vw(50px); 
                a{
                   display: flex;
                align-items: center;
                justify-content:center;                
                color:$negro;
                span{ margin-top: get-vw(10px);margin-left:get-vw(20px);  }
                }
                
            } 
            .menuactivo{border-bottom: 1px solid $negro;border-right: 1px solid $negro;border-left: 1px solid $negro;}
            nav{display: none;}
            .menu{
                position: absolute;
                max-width:  get-vw(560px); 
                margin-top: get-vw(0px); ;
                z-index: 99999;
                width: 100%;
                li{
                    width: 100%;
                    display: block;
                    background: $blanco;
                    line-height: get-vw(50px); 
                    border: 1px solid $negro;
                    font-size: get-vw(50px); 
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: get-vw(15px); 
                        color:$negro;
                        width: 100%;
                        max-width:  90%; 
                    }
                    .open{
                        font-size: get-vw(25px); 
                        border: 1px solid $negro;
                        padding-left: get-vw(30px); 
                        padding-right: get-vw(30px); 
                        border-radius: get-vw(30px); 
                        &:hover{background: $negro;}
                    }
                }
                .menu1{margin-left: 18%;
                       &:hover{background: $verde;
                               .open{background: $negro;color:$verde;}
                       }
                }
                .menu2{margin-left: 36%;
                       &:hover{background: $amarillo;
                               .open{background: $negro;color:$amarillo;}
                       }
                }
                .menu3{margin-left: 18%;
                       &:hover{background: $azul;
                               .open{background: $negro;color:$azul;}
                       }
                }
                .menu4{margin-left: 0%;
                       &:hover{background: $rojo;
                               .open{background: $negro;color:$rojo;}
                       }
                }
            }
        }
            

        
                 
        
}