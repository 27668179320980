@media screen and (max-width: 1280px){
.main-sectors{   
    .content{           
        .title{
            width: 100%;                      
            padding-top:  get-vw-portatil(15px);
            padding-bottom: get-vw-portatil(15px);
white-space: nowrap;
            h2{
                text-align: center;
                width: 100%;   
                max-width: 90%;               
               font-size: get-vw-portatil(74px);
               line-height: get-vw-portatil(74px);
               padding-top: get-vw-portatil(10px);            
               white-space: nowrap;
            }                      
        }   
        .marquesina{overflow: auto;border-bottom: 1px solid $negro;margin-top: -5px;}
        .cycle-slideshow{          
            margin-top: -2px;
            width: 100%;
            .trabajos
            {
                width: 100%;                
                text-align: center;
                .new{
                    position: absolute;
                    right: 10%;top:10%;
                    font-size:get-vw-portatil(40px);                 
                    border-radius: 50%;
                    width: get-vw-portatil(120px);
                    height:  get-vw-portatil(120px);
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    line-height: get-vw-portatil(40px);
                    border-radius: 50%;                   
                    span{padding-left: 10px;padding-top: 10px;}
                }
                img{max-height:get-vw-portatil(500px);}
            }
            h3{font-size: get-vw-portatil(50px);line-height:get-vw-portatil(50px); }
            .btn{margin-bottom: get-vw-portatil(50px);margin-top: get-vw-portatil(30px); font-size:get-vw-portatil(25px); }
            #custom-caption{right: 10%;bottom:0;margin-bottom: get-vw-portatil(50px);z-index: 999999;font-size:get-vw-portatil(25px);}
        }
        .carga{
               padding-top: get-vw-portatil(15px);;
               padding-bottom: get-vw-portatil(15px);;
        p{padding-left: get-vw-portatil(15px);;padding-right: get-vw-portatil(15px);max-width: 90%;}
        }
        .content_texto{
            width: 100%;           
            margin-bottom: get-vw-portatil(30px);
            .texto{
                max-width: 90%;
            }
            .titulo{
                h2{font-size: get-vw-portatil(80px);line-height: get-vw-portatil(90px);}
            } 
            .carga{
                 max-width: 90%;
            }
        }
        .texto{           
            width: 100%;           
            padding-top:get-vw-portatil(30px);
            padding-bottom: get-vw-portatil(30px);
            p{width: 100%;padding-bottom: get-vw-portatil(50px);}
        }
        .equipo{          
            width: 100%;           
            max-width: 90%;
            margin-bottom: get-vw-portatil(100px);;
            div{width: 100%;max-width: 30%;}
            h4{font-size: get-vw-portatil(40px);line-height: get-vw-portatil(50px); text-align: center;margin-top: get-vw-portatil(20px);}
            h5{font-size: get-vw-portatil(24px);line-height: get-vw-portatil(30px); text-align: center;margin-bottom:get-vw-portatil(30px);}
            .linksocial{text-align: left;margin-bottom: get-vw-portatil(40px);;margin-top: get-vw-portatil(40px);;}
        }
        .works{          
            width: 100%;
             text-align: left;
            max-width: 90%;
            margin-bottom: get-vw-portatil(100px);;          
            .item{width: 100%;max-width: 24.5%;margin-top: get-vw-portatil(35px);color:#000;border: 2px solid $azul;}
            h4{font-size: get-vw-portatil(25px);line-height: get-vw-portatil(35px); text-align: left;margin-top:  get-vw-portatil(15px);;}
            .contentWorkImg{              
                padding: get-vw-portatil(5px);
                width: 100%;
                img{max-height: get-vw-portatil(250px);}
            }            
        }
        .servicios{           
            width: 100%;          
            max-width: 90%;
            margin-bottom: get-vw-portatil(100px);;           
            div{width: 100%;max-width: 48%;margin-bottom: get-vw-portatil(50px);}
            h4{font-size: get-vw-portatil(70px);line-height: get-vw-portatil(80px); text-align: center;margin-top: get-vw-portatil(20px);min-height: get-vw-portatil(160px);}
            h5{font-size: get-vw-portatil(25px);line-height: get-vw-portatil(30px); text-align: center;margin-bottom: get-vw-portatil(30px);;}           
        }
        .contacto{
            p{padding: 0px;padding-bottom: get-vw-portatil(20px);}
            a{}
            .textContact{
                font-size: get-vw-portatil(50px);line-height: get-vw-portatil(70px);
            }
        }
    }  
    .detalle{       
        z-index: 999;
        max-width: 85%;
        .closedetalle{
            font-size:get-vw-portatil(25px);          
            margin-top: get-vw-portatil(25px);
            margin-right: get-vw-portatil(25px);
        }
        .galeria{           
            width: 90%;          
            padding: 5%;
            div{               
                width: 100%;
                img{}
            }
            
        }
        .informacion{            
            width: 100%;
            color:#000;
            margin-bottom: -5%;
            -webkit-box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            -moz-box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            max-width: 89%;
            a{}
            h3{padding: get-vw-portatil(15px);;font-size:get-vw-portatil(25px);}
            .contentInformacion{padding:get-vw-portatil(15px);}
        }
    }
}
}

