.main-sectors{
    display: block;
    width: 100%;    
    position: relative;
    align-self : flex-start;
    flex: 1;
    margin:0 auto;
    .content{
            display: inline-block;
            text-align: left;
            width: 100%;
        .title{
            width: 100%;           
            z-index: 9;  
            text-align: center;
            padding-top:  get-vw(15px);
            padding-bottom: get-vw(15px);
white-space: nowrap;
            h2{
                text-align: center;
                width: 100%;   
                max-width: 90%;
                display: inline-block;  
               font-size: get-vw(74px);
               line-height: get-vw(74px);
               padding-top: get-vw(10px);
               color:$negro;  
               white-space: nowrap;
            }                      
        }   
        .marquesina{overflow: auto;border-bottom: 1px solid $negro;margin-top: -5px;}
        .cycle-slideshow{
            display: block;
            margin-top: -2px;
            width: 100%;
            .trabajos
            {
                width: 100%;                
                text-align: center;
                .new{
                    position: absolute;
                    right: 10%;top:10%;
                    font-size:get-vw(40px);
                    border: 1px solid $negro;
                    border-radius: 50%;
                    width: get-vw(120px);
                    height:  get-vw(120px);
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    line-height: get-vw(40px);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    span{padding-left: 10px;padding-top: 10px;}
                }
                img{max-height:get-vw(500px);}
            }
            h3{font-size: get-vw(50px);line-height:get-vw(50px); }
            .btn{margin-bottom: get-vw(50px);margin-top: get-vw(30px); font-size:get-vw(25px); }
            #custom-caption{position: absolute;right: 10%;bottom:0;margin-bottom: get-vw(50px);z-index: 999999;font-size:get-vw(25px);}
        }
        .carga{display: inline-block;
               padding-top: 15px;
               padding-bottom: 15px;
               text-align: center;
        width: 100%;
        p{width: 100%;padding-left: 15px;padding-right: 15px;text-align: left;max-width: 90%;display: inline-block;text-align: left;}
        }
        .content_texto{
            width: 100%;
            display: block;
            margin-bottom: 30px;
            text-align: center;
            .texto{
                max-width: 90%;
                text-align: center;
                border-bottom: 3px solid $negro;
            }
            .titulo{
                display: inline-block;
            text-align: center;
                h2{font-size: get-vw(80px);line-height: get-vw(90px); text-decoration: underline;text-align: center;}
            } 
            .carga{
                 max-width: 90%;
            }
        }
        .texto{
            display: inline-block;
            width: 100%;
            text-align: left;;
            padding-top: 30px;
            padding-bottom: 30px;
            background: $blanco;
            p{text-align:left;display: inline-block;width: 100%;padding-bottom: 50px;}
        }
        .equipo{
            display: inline-flex;
            flex-wrap:nowrap;      
            width: 100%;
             text-align: left;
            max-width: 90%;
            margin-bottom: get-vw(100px);;
            justify-content: space-between;
            div{width: 100%;max-width: 30%;margin-bottom:get-vw(30px); }
            h4{font-size: get-vw(40px);line-height: get-vw(50px); text-align: center;margin-top: 20px;}
            h5{font-size: get-vw(24px);line-height: get-vw(30px); text-align: center;margin-bottom: 30px;}
            .linksocial{text-align: left;margin-bottom: 40px;margin-top: 40px;text-decoration: underline;color:#000;}
        }
        .works{
           display: inline-flex;
            flex-wrap:wrap;      
            width: 100%;
             text-align: left;
            max-width: 90%;
            margin-bottom: get-vw(100px);;
            justify-content: space-between;
            align-items: self-end;
            .item{width: 100%;max-width: 24.5%;margin-top: get-vw(35px);color:#000;border: 2px solid $azul;display: flex;
flex-wrap: wrap;justify-content: stretch;}
            h4{font-size: get-vw(25px);line-height: get-vw(35px); text-align: left;margin-top:  get-vw(15px);;}
            .contentWorkImg{
                display: flex;
                flex-wrap:wrap;     
                justify-content: space-around;
                align-items: center;
                text-align: center;
                min-height: 250px;
                padding: get-vw(5px);
                width: 100%;
                img{max-height: get-vw(250px);}
            }            
        }
        .servicios{
            display: inline-flex;
            flex-wrap:wrap;      
            width: 100%;
             text-align: left;
            max-width: 90%;
            margin-bottom: get-vw(100px);;
            justify-content: space-between;
            div{width: 100%;max-width: 48%;margin-bottom: get-vw(50px);}
            h4{font-size: get-vw(70px);line-height: get-vw(80px); text-align: center;margin-top: 20px;text-decoration: underline;min-height: get-vw(160px);}
            h5{font-size: get-vw(25px);line-height: get-vw(30px); text-align: center;margin-bottom: 30px;}           
        }
        .contacto{
            p{text-align: center!important;padding: 0px;padding-bottom: get-vw(20px);}
            a{color:#000;}
            .textContact{
                font-size: get-vw(50px);line-height: get-vw(70px);
            }
        }
    }  
    .detalle{
        display:block;
        width: 100%;
        float: right;
        position: relative;
        z-index: 999;
        max-width: 85%;
        .closedetalle{
            font-size:get-vw(25px);
            float: right;
            margin-top: get-vw(25px);
            margin-right: get-vw(25px);
        }
        .galeria{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            width: 90%;
            text-align: center;
            padding: 5%;
            div{
                text-align: center;
                width: 100%;
                img{display: inline-block;text-align: center;}
            }
            
        }
        .informacion{
            float: right;
            background: $blanco;
            width: 100%;
            color:#000;
            margin-bottom: -5%;
            -webkit-box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            -moz-box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            box-shadow: -5px 6px 5px -5px rgba(0,0,0,0.75);
            max-width: 89%;
            a{color:#000;}
            h3{padding: get-vw(15px);;font-size:get-vw(25px);}
            .contentInformacion{display: none;padding:get-vw(15px); text-align: left;}
        }
    }
}


/*----------------------------MODALES----------------------------*/


.modalPosicion{
    position: relative;
    background-color:$blanco;
    width:100%;
    max-width: get-vw(1180px);
    padding: 10px get-vw(50px);;
    border-radius:  get-vw(30px); 
    margin: 13.5% auto;
    position: relative;
    h3{

        font-size:get-vw(26px);     
        color:$gris2;
        text-align: left;
        margin-bottom: get-vw(20px);     
    }
}                  
#modalPosicion:target{
    display: block;
}


.modalVisita{
    position: relative;
    background-color:$blanco;
    width:100%;
    max-width: get-vw(590px);
    padding: 10px get-vw(50px);;
    border-radius:  get-vw(30px); 
    margin: 10.5% auto;
    position: relative;
    h3{

        font-size:get-vw(26px);     
        color:$gris2;
        text-align: left;
        margin-bottom: get-vw(20px);     
    }
    .campos,.parte1{width: 100%!important;}
    .bloque{margin-top: 0px!important;}
}                  
#modalVisita:target{
    display: block;
}
