
$negro:#000000;
$verde:#54C460;
$azul:#5AC8EA;
$amarillo:#FFB834;
$lila:#9571E2;
$rojo:#FF5852;

$fill:#F4F4F4;
$gris:#999999;
$gris2:#666666;
$blanco:#FFF;
$bordesgrises:#e5e5e5;



/*vars*/
$bezier1: cubic-bezier(0.23, 1, 0.32, 1);
$bezier2:cubic-bezier(.19,1,.22,1); 
$gw-wraper: 1500px;


$max-txt-limit: 1900px;



@smartphones: ~"only screen and (max-width: 479px)";
@tablet: ~"only screen and (max-width: 768px)";

