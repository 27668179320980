@media screen and (max-width: 500px){
footer{  
        padding-bottom:  get-vw-mobile(15px);         
        width: 100%;           
        margin: 0 auto;
        margin-top: -10px;
    .content_bloques{
        width: 100%;
        max-width: 90%;
        padding-top: get-vw-mobile(20px); 
        font-size: get-vw-mobile(20px);        
        h2{width: 100%;}
    }   
}
}