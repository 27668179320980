@media screen and (max-width: 500px){
#main-header{       
        padding-bottom:  get-vw-mobile(0px);  
        max-width: 100%;      
        width: 100%;            
        margin: 0 auto;
        .content{             
            width: 100%;  
            max-width: 100%;
            padding-top: get-vw-mobile(0px);            
            h1{
                width: 100%;
                display: block;
                padding-top: get-vw-mobile(10px);  
                padding-bottom:  get-vw-mobile(10px);  
                max-width:  90%; 
                padding-right: get-vw-mobile(40px); 
                font-size: get-vw-mobile(25px); 
                a{                 
                span{ margin-top: get-vw-mobile(10px);margin-left:get-vw-mobile(20px);  }
                }
                
            } 
            .menuactivo{}
            nav{display: none;}
            .menu{
                position: absolute;
                max-width:  90%;  
                margin-top: get-vw-mobile(0px); ;            
                li{
                    width: 90%;                   
                    line-height: get-vw-mobile(25px);                   
                    font-size: get-vw-mobile(25px); 
                    a{                      
                        padding: get-vw-mobile(10px);                       
                        width: 100%;
                        max-width:  90%; 
                    }
                    .open{
                        font-size: get-vw-mobile(20px); 
                        border: 1px solid $negro;
                        padding-left: get-vw-mobile(20px); 
                        padding-right: get-vw-mobile(20px); 
                        border-radius: get-vw-mobile(20px); 
                        &:hover{background: $negro;}
                    }
                }
                .menu1{margin-left: 10%;
                       &:hover{background: $verde;
                               .open{background: $negro;color:$verde;}
                       }
                }
                .menu2{margin-left: 20%;
                       &:hover{background: $amarillo;
                               .open{background: $negro;color:$amarillo;}
                       }
                }
                .menu3{margin-left: 10%;
                       &:hover{background: $azul;
                               .open{background: $negro;color:$azul;}
                       }
                }
                .menu4{margin-left: 0%;
                       &:hover{background: $rojo;
                               .open{background: $negro;color:$rojo;}
                       }
                }
            }
        }
            
 .bt-menu{
            img{max-height:get-vw-mobile(50px); }
        }
        
                 
        
}    
}