@import "reset";


@import "_variables";
@import "_mixins";
@import "_fonts"; 
  
//desktop 

@import "_header";
@import "_home"; 
@import "_footer"; 

//portatil

@import "portatil/_header";
@import "portatil/_home"; 
@import "portatil/_footer";

//tablet

@import "tablet/_header";
@import "tablet/_home"; 
@import "tablet/_footer"; 



//mobile

@import "mobile/_header";
@import "mobile/_home"; 
@import "mobile/_footer"; 

body{   
    olor:$negro;
    font-family: $AvenueMono-Regular;
    font-size:get-vw(16px);
    line-height: get-vw(25px);    
    text-align: center;
    display: inline-flex;
  flex-direction: column;
  justify-content:Space-around;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  background: $blanco;
}

.wrapper
{
    flex: 1;    
    display: inline-flex;
    flex-direction: column;
    margin: 0 auto;   
    width: 100%;
    min-height:get-vw(700px);
    max-width: 100%;
}
header{align-self : flex-start;}
footer{align-self : flex-end;}

a{color:$azul;text-decoration: none;}
img{max-width: 100%;}
.clear{clear:both;}
.mvlOn{display: none;}
.ocultar{display: none;}
.bordetopbottom{border-top: 1px solid $negro;border-bottom:  1px solid $negro;}
.fondoazul{background: $azul;
          .btn{color: $azul!important;}
}
.fondoamarillo{background: $amarillo;.btn{color: $amarillo!important;}
}
.fondoverde{background: $verde;.btn{color: $verde!important;}
}
.fondolila{background: $lila;.btn{color: $lila!important;}
}
.fondorojo{background: $rojo;.btn{color: $rojo!important;}
}
.fondonegro{background: $negro;.btn{color: $negro!important;}
}
.fondoblanco{background: $blanco;.btn{color: $blanco!important;}
}
.centro{display: inline-block;width: 100%;text-align: center;}





.btn{
    border: 2px solid $negro;
    border-radius: 20px;
    cursor: pointer;
    color:transparent;
    display: inline-block;
    background: $negro;
    font-size: get-vw(16px);
    padding: get-vw(10px) get-vw(30px);
    &:hover{
        background: $blanco;
        color:$negro!important;
    }
}
.btLogin:hover{
        -webkit-box-shadow: 5px 5px 5px 0px rgba(229,229,229,1);
        -moz-box-shadow: 5px 5px 5px 0px rgba(229,229,229,1);
        box-shadow: 5px 5px 5px 0px rgba(229,229,229,1);
    }



strong{font-weight: bold!important;}


.cerrarcandado{display: flex;
flex-wrap: Nowrap;
justify-content: flex-start;
align-items: center;
align-content: center;
margin-left: 20px;
img{margin-right: 5px;}
}

.mosaico{
    display: inline-block;
    border-bottom:1px solid $negro;
    margin: 0px;
    padding: 0px;
    width: 100%;
    .fila{
        display: flex;
        width: 100%;
        border-top:1px solid $negro;
        .items{border-left: 1px solid $negro;height: 60px;}
        .item10{width: 10%;}
        .item20{width: 20%;}
        .item30{width: 30%;}
        .item40{width: 40%;}
        .item50{width: 50%;}
        .item60{width: 60%;}
        .item70{width: 70%;}
        .item80{width: 80%;}
        .item90{width: 90%;}
        .item100{width: 100%;}
    }
    
}

.faldonDeCookies{
    display: inline-block;
    width: 100%;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $blanco;    
    a{color:$negro;}
    p{padding-left: 15px;padding-right: 15%;}
}

@media screen and (max-width: 500px){
   .mvlOff{display: none;}
   .mvlOn{display: inherit;}   
   .wrapper
    {
        width: 100%;
        min-height:get-vw-mobile(650px);
    }
    .cerrarcandado{
margin-left: 0px;
img{margin-right: 10px;}
}
}

