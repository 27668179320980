footer{
   display: inline-block;
        justify-content: center;
        flex-wrap:wrap;   
        padding-bottom:  get-vw(15px);  
        text-align: center;
        width: 100%;    
        text-align: center;         
        margin: 0 auto;
        margin-top: -10px;
    .content_bloques{
        display: inline-flex;
        width: 100%;
        max-width: 90%;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding-top: get-vw(20px); 
        font-size: get-vw(20px); 
        text-align: center;
        h2{width: 100%;display: inline-block;text-align: center;}
    }   
}