@font-face {
  font-family: 'AvenueMono-Regular';
  src: url('fonts/AvenueMono-Regular.eot?#iefix') format('embedded-opentype'),  url('fonts/AvenueMono-Regular.woff') format('woff'), url('fonts/AvenueMono-Regular.ttf')  format('truetype'), url('fonts/AvenueMono-Regular.svg#AvenueMono-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}




/*@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff2') format('woff2'), url('fonts/fontawesome-webfont.woff') format('woff'), url('fonts/fontawesome-webfont.ttf') format('truetype'), url('fonts/fontawesome-webfont.svg#robotobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

$iconos:'FontAwesome';*/


$AvenueMono-Regular:'AvenueMono-Regular';
